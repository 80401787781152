// 服务管理
import { post } from '@/http/http'

// 服务标签查询
export const serveLabelQuery = p => post('api/v1.0/serveinfo/serve_label_get', p)

// 服务标签新增
export const serveLabelAdd = p => post('api/v1.0/serveinfo/serve_label_add', p)

// 服务标签编辑
export const serveLabelEdit = p => post('api/v1.0/serveinfo/serve_label_update', p)

// 服务标签删除
export const serveLabelDel = p => post('api/v1.0/serveinfo/serve_label_delete', p)

// 服务标签下拉
export const serveLabelDown = p => post('api/v1.0/serveinfo/serve_label_drop_get', p)

// 服务分组-一级查询
export const serveGroupSuper = p => post('api/v1.0/serveinfo/serve_group_get', p)

// 服务分组-一级下拉
export const serveGroupSD = p => post('api/v1.0/serveinfo/serve_group_drop', p)

// 服务分组-二级查询
export const serveGroupLevel = p => post('api/v1.0/serveinfo/serve_group_low', p)

// 服务分组-[一二级]新增
export const serveGroupAdd = p => post('api/v1.0/serveinfo/serve_group_add', p)

// 服务分组-[一二级]编辑
export const serveGroupEdit = p => post('api/v1.0/serveinfo/serve_group_update', p)

// 服务分组-[一二级]删除
export const serveGroupDel = p => post('api/v1.0/serveinfo/serve_group_delete', p)

// 服务分组-[一二级]展示or不展示
export const serveGroupIsOk = p => post('api/v1.0/serveinfo/serve_group_isok', p)

// 服务[列表]查询
export const serveListQuery = p => post('api/v1.0/serveinfo/serve_info_get', p)

// 服务列表[上下架]
export const serveListUpAndLow = p => post('api/v1.0/serveinfo/serve_info_putaway', p)

// 服务列表[删除]
export const serveInfoDel = p => post('api/v1.0/serveinfo/serve_info_delete', p)

// 服务[基本信息]添加
export const serveInfoAdd = p => post('api/v1.0/serveinfo/serve_info_add', p)

// 服务[基本信息]编辑
export const serveInfoEdit = p => post('api/v1.0/serveinfo/serve_info_update', p)

// 服务管理[基本信息]查询
export const serveInfoQuery = p => post('api/v1.0/serveinfo/serve_info_get_id', p)

// 服务管理[服务设置]查询
export const serveSetQuery = p => post('api/v1.0/serveinfo/serve_info_setting_id', p)

// 服务管理[服务设置]编辑
export const serveSetEdit = p => post('api/v1.0/serveinfo/serve_info_setting_update', p)

// 服务管理[可售门店]查询
export const serveStoreQuery = p => post('api/v1.0/serveinfo/serve_shop_setting_get', p)

// 服务管理[可售门店]编辑
export const serveStoreEdit = p => post('api/v1.0/serveinfo/serve_shop_setting_update', p)

// 服务分组排序
export const serveGroupOper = p => post('api/v1.0/serveinfo/serve_group_oper', p)

// 服务列表排序下拉查询
export const serveSortGet = p => post('api/v1.0/serveinfo/serve_sort_down', p)

// 排序修改
export const serveSortUpdate = p => post('api/v1.0/serveinfo/serve_sort_update', p)
